import React from 'react';
import LoaderImgSvg from '../../../images/rupees_icon.svg';
import {ReactSVG} from 'react-svg'

const PhocketLoader = ({ }) => (
    <div className="phocket-loader">
        <ReactSVG
            src={LoaderImgSvg}
            className="phocket-loader-img"
        />
    </div>
)

export default PhocketLoader;