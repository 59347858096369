import initialState from '../../../store/InitialState.config';

export const AdminReducer = (state = {}, action) => {
    switch (action.type) {
        case 'CHANGE_SETTINGS_SIDENAV':
            return {
                ...state,
                visibleSideNav: action.sideNavSelected
            }
        case 'CHANGE_CONTENT_VIEW':
            return {
                ...state,
                [state.visibleSideNav]: {
                    selectedNavProp: action.selectedNavIndex
                }
            }
        case 'RESET_SETTINGS':
            return {
                ...state,
                OPTIONS: {
                    selectedNavProp: 0
                },
                visibleSideNav: "OPTIONS"
            }
        case 'CHANGE_USERNAV':
            return {
                ...state,
                visibleUserSideNav: action.userNavSelected
            }
        case 'CHANGE_CONTENT_VIEW_USER':
            return {
                ...state,
                [state.visibleUserSideNav]: {
                    selectedNavProp: action.selectedNavIndexUser
                }
            }
        case 'RESET_USERS':
            return {
                ...state,
                UserOptions: {
                    selectedNavProp: 0
                },
                visibleUserSideNav: "UserOptions"
            }
        default: return state;
    }
}

export const UserDetailReducer = (state = {}, action) => {
    switch (action.type) {
        case 'GET_ALL_USER_DETAILS':
            return action.allUserDetails;

        default: return state;
    }

}

export const AdminUserReducer = (state = {}, action) => {
    switch (action.type) {
        case 'GET_ADMIN_USER_DETAILS':
            return action.adminDetails;

        default: return state;
    }

}

export const DuePaymentReducer = (state = {}, action) => {
    switch (action.type) {
        case 'GET_ALL_DUE_PAYMENT':
            return action.allDuePayment;
        default: return state;
    }
}

export const DuePaymentEMIReducer = (state = {}, action) => {
    switch (action.type) {
        case 'GET_ALL_DUE_PAYMENT_EMI':
            return action.allDuePaymentEmi;
        default: return state;
    }
}

export const IncomingPaymentReducer = (state = {}, action) => {
    switch (action.type) {
        case 'GET_ALL_INCOMING_PAYMENT':
            return action.allIncomingPayment;

        default: return state;
    }

}

export const LatestDocUploadReducer = (state = {}, action) => {
    switch (action.type) {
        case 'GET_ALL_LATEST_DOCUMENT_UPLOAD':
            return action.latestDocUpload;

        default: return state;
    }

}

export const LEadByMobileNumber = (state = {}, action) => {
    switch (action.type) {
        case 'GET_LEAD_BY_NUMBERS':
            return action.leadByNumber;

        default: return state;
    }

}

export const LoanAddaLeads = (state = {}, action) => {
    switch (action.type) {
        case 'GET_LOAN_ADDAL_LEAD':
            return action.loanAddaLeads;

        default: return state;
    }

}

export const LoanApprovalLead = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_LOAN_APPROVAL_LEAD':
            return action.allPendingPayment;
        case 'ADD_LOAN_APPROVAL_LEAD':
            return [...state, ...action.allPendingPayment];
        default: return state;
    }
}
export const loanApprovalLeadGiftCard = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_LOAN_APPROVAL_LEAD_GIFT_CARD':
            return action.allPendingPayment;
        case 'ADD_LOAN_APPROVAL_LEAD_GIFT_CARD':
            return [...state, ...action.allPendingPayment];
        default: return state;
    }
}

export const LoanApprovalLeadByPriority = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_LOAN_APPROVAL_LEAD_BY_PRIORITY':
            return action.loanApprovalLeadByPriority;
        case 'ADD_LOAN_APPROVAL_LEAD':
            return [...state, ...action.loanApprovalLeadByPriority];
        default: return state;
    }
}

export const DisburseLead = (state = {}, action) => {
    switch (action.type) {
        case 'GET_DISBURSE_LEAD':
            return action.disburseLeads;
        default: return state;
    }
}

export const ImportantLead = (state = {}, action) => {
    switch (action.type) {
        case 'GET_IMPORTANT_LEAD':
            return action.importantLeads;
        default: return state;
    }
}

export const LeadManagementReducer = (state = {}, action) => {
    switch (action.type) {
        case 'GET_LEAD_MANAGEMENT':
            return action.LeadManagementData;
        default: return state;
    }
}

export const crmNotificationReducer = (state = {}, action) => {
    switch (action.type) {
        case 'GET_CRM_NOTIFICATION':
            return action.crmNotification;
        default: return state;
    }
}

export const RejectUserReducer = (state = {}, action) => {
    switch (action.type) {
        case 'GET_REJECT_LIST':
            return action.reject;

        default: return state;
    }

}
export const StatusReducer = (state = {}, action) => {
    switch (action.type) {
        case 'GET_ALL_STATUS':
            return action.status;

        default: return state;
    }

}

export const AllAmbassadorDataAdmin = (state = {}, action) => {
    switch (action.type) {
        case 'ALL_AMBASSADOR_DATA_ADMIN':
            return action.status;
        default: return state;
    }
}
export const ambasadorDetailsReducer = (state = {}, action) => {
    switch (action.type) {
        case 'GET_AMBASADOR_DETAILS':
            return action.status;

        default: return state;
    }

}
export const loyalityDetailsReducer = (state = {}, action) => {
    switch (action.type) {
        case 'GET_LOYALITY_DETAILS':
            return action.status;

        default: return state;
    }

}

export const activityDataReducer = (state = {}, action) => {
    switch (action.type) {
        case 'GET_ACTIVITY_DETAILS':
            return action.status;

        default: return state;
    }

}
export const creditDashBoardData = (state = {}, action) => {
    switch (action.type) {
        
        case 'GET_CREDIT_DASHBOARD_DATA':
            return action.creditDashBoardDataValue;

        default: return state;
    }

}
export const creditConcludedDataReducer = (state = {}, action) => {
    switch (action.type) {
        
        case 'GET_CREDIT_CONCLUDED_DATA':
            return action.creditConcludedData;

        default: return state;
    }

}
export const LoanApprovalLeadOptimized = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_LOAN_APPROVAL_LEAD_OPTIMIZED':
            return action.allPendingPayment;
        case 'ADD_LOAN_APPROVAL_LEAD_OPTIMIZED':
            return [...state, ...action.allPendingPayment];
        default: return state;
    }
}

export const LeadManagementOptimized = (state = {}, action) => {
    switch (action.type) {
        case 'GET_LEAD_MANAGEMENT_OPTIMIZED':
            return action.LeadManagementData;
        default: return state;
    }
}